

.card {
  border: none;
  text-align: left;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.stars {
  margin-bottom: 10px;
}

.stars i {
  color: gold;
  margin-right: 5px;
}

.user-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.card-title {
  font-weight: bold;
  margin: 0; /* Remove margin for better alignment */
}

.card-subtitle {
  font-size: 0.9em;
  margin-top: 2px; /* Reduce gap between title and subtitle */
}

.slick-dots li button:before {
  color: #ff0000;
}

.slick-dots li.slick-active button:before {
  color: #ff0000 !important;
}

/* Responsive adjustments */
/* @media (max-width: 1024px) {
  .card {
    margin: 0 auto;
  }
}

@media (max-width: 600px) {
  .card {
    margin: 0 auto;
  }
}

@media (max-width: 480px) {
  .card {
    margin: 0 auto;
  }
} */
